body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #30af5f;
}

.landing {
  display: grid;
  align-content: center;
  justify-items: center;
  min-height: 100vh;
  img {
    height: 55px;
  }
  p {
    color: #fff;
    position: fixed;
    bottom: 10px;
    left: 20px;
    font-size: 12px;
  }
}